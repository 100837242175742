const syllabus = [
  {
    name: "PT-3 (CLASS-IX) 2024-25",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2024-25/PT-3-(CLASS-IX)-2024-25.pdf",
  },
  {
    name: "PT-2 (CLASS-VIII) 2024-25",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2024-25/PT-2-(CLASS-VIII)-2024-25.pdf",
  },
  {
    name: "PT-2 (CLASS-VII) 2024-25",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2024-25/PT-2-(CLASS-VII)-2024-25.pdf",
  },
  {
    name: "PT-2 (CLASS-VI) 2024-25",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2024-25/PT-2-(CLASS-VI)-2024-25.pdf",
  },
  {
    name: "PROFICIENCY (CLASS XII) 2024-25",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2024-25/PROFICIENCY-(CLASS-XII)-2024-25.pdf",
  },
  {
    name: "PROFICIENCY (CLASS X) 2024-25",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2024-25/PROFICIENCY-(CLASS-X)-2024-25.pdf",
  },
  {
    name: "UT-3 (CLASS XII) 2024-25",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2024-25/UT-3-(CLASS-XII)-2024-25.pdf",
  },
  {
    name: "PT-3 (CLASS X) 2024-25",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2024-25/PT-3-(CLASS-X)-2024-25.pdf",
  },
  {
    name: "MID TERM (CLASS XI) 2024-25",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2024-25/MID-TERM-(CLASS-XI)-2024-25.pdf",
  },
  {
    name: "MID TERM (CLASS IX) 2024-25",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2024-25/MID-TERM-(CLASS-IX)-2024-25.pdf",
  },
  {
    name: "MID TERM (CLASS VIII) 2024-25",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2024-25/MID-TERM-(CLASS-VIII)-2024-25.pdf",
  },
  {
    name: "MID TERM (CLASS VII) 2024-25",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2024-25/MID-TERM-(CLASS-VII)-2024-25.pdf",
  },
  {
    name: "MID TERM (CLASS VI)  2024-25",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2024-25/MID-TERM-(CLASS-VI)-2024-25.pdf",
  },

  {
    name: "CLASS XII (UT-2) SYLLABUS",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2024-25/CLASS-XII-(UT-2)-SYLLABUS.pdf",
  },
  {
    name: "CLASS XI (UT-1) SYLLABUS",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2024-25/CLASS-XI-(UT-1)-SYLLABUS.pdf",
  },
  {
    name: "CLASS X (PT-2) SYLLABUS",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2024-25/CLASS-X-(PT-2)-SYLLABUS.pdf",
  },
  {
    name: "CLASS IX (PT-2) SYLLABUS",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2024-25/CLASS-IX-(PT-2)-SYLLABUS.pdf",
  },
  {
    name: "CLASS PREP (2024-2025)",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2024-25/CLASS-PREP-SYLLABUS-2024-25.pdf",
  },
  {
    name: "CLASS NURSERY (2024-2025)",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2024-25/CLASS-NURSERY-SYLLABUS-2024-25.pdf",
  },
  {
    name: "CLASS I (2024-2025)",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2024-25/CLASS-I-SYLLABUS-2024-25.pdf",
  },
  {
    name: "CLASS II (2024-2025)",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2024-25/CLASS-II-SYLLABUS-2024-25.pdf",
  },

  {
    name: "CLASS III (2024-2025)",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2024-25/CLASS-III-SYLLABUS-2024-25.pdf",
  },
  {
    name: "CLASS IV (2024-2025)",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2024-25/CLASS-IV-SYLLABUS-2024-25.pdf",
  },
  {
    name: "CLASS V (2024-2025)",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2024-25/CLASS-V-SYLLABUS-2024-25.pdf",
  },

  {
    name: "CLASS VI (2024-2025)",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2024-25/CLASS-VI-(2024-2025).pdf",
  },
  {
    name: "CLASS VII (2024-2025)",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2024-25/Class-VII-(2024-2025).pdf",
  },
  {
    name: "CLASS VIII (2024-2025)",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2024-25/Class-VIII-(2024-2025).pdf",
  },
  {
    name: "CLASS IX (2024-2025)",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2024-25/CLASS-IX-(2024-2025).pdf",
  },
  {
    name: "CLASS X (2024-2025)",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2024-25/CLASS-X-(2024-20250.pdf",
  },
  {
    name: "CLASS XI (2024-2025)",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2024-25/CLASS-XI-(2024-2025).pdf",
  },
  {
    name: "CLASS XII (2024-2025)",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2024-25/CLASS-XII-(2024-2025).pdf",
  },

  /*  {
    name: "Annual Examination Syllabus Class XI",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023-24/Annual-Examination-Syllabus-Class%20XI.pdf",
  },
  {
    name: "Annual Examination Syllabus Class IX",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023-24/CLASS-IX-ANNUAL-EXAM-SYLLABUS.pdf",
  },
  {
    name: "Annual Examination Syllabus Class VIII",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023-24/CLASS-VIII-ANNUAL-EXAM-SYLLABUS.pdf",
  },
  {
    name: "Annual Examination Syllabus Class VII",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023-24/CLASS-VII-ANNUAL-EXAM-SYLLABUS.pdf",
  },
  {
    name: "Annual Examination Syllabus Class VI",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023-24/CLASS-VI-ANNUAL-EXAM-SYLLABUS.pdf",
  },
  {
    name: "Annual Examination Syllabus Class V",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023-24/CLASS-V-ANNUAL-EXAM-SYLLABUS.pdf",
  },
  {
    name: "Annual Examination Syllabus Class IV",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023-24/CLASS-IV-ANNUAL-EXAM-SYLLABUS.pdf",
  },
  {
    name: "Annual Examination Syllabus Class III",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023-24/CLASS-III-ANNUAL-EXAM-SYLLABUS.pdf",
  },
  {
    name: "UT-II Syllabus Class XI",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023-24/UT-II-Syllabus-class-XI.pdf",
  },
  {
    name: "PT3 Syllabus Class IX",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023-24/PT3-Syllabus-Class-IX.pdf",
  },
  {
    name: "PT2 Syllabus Class VIII",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023-24/PT2-Syllabus-Class-VIII.pdf",
  },
  {
    name: "PT2 Syllabus Class VII",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023-24/PT2-Syllabus-Class-VII.pdf",
  },
  {
    name: "PT2 Syllabus Class VI",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023-24/PT2-Syllabus-Class-VI.pdf",
  },
  {
    name: "PERIODIC II EXAMINATION - CLASS III",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023-24/PERIODIC-II-SYLLABUS-CLASS-III-2023-24.pdf",
  },
  {
    name: "PERIODIC II EXAMINATION - CLASS IV",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023-24/PERIODIC-II-SYLLABUS-CLASS-IV-2023-24.pdf",
  },
  {
    name: "PERIODIC II EXAMINATION - CLASS V",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023-24/PERIODIC-II-SYLLABUS-CLASS-V-2023-24.pdf",
  },
  {
    name: "Class IV Mid Term Syllabus",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023-24/CLASS-IV-MID-TERM-SYLLABUS.PDF",
  },
  {
    name: "Class V Mid Term Syllabus",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023-24/CLASS-V-MID-TERM-SYLLABUS.PDF",
  },
  {
    name: "Class VI Mid Term Syllabus",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023-24/CLASS-VI-MID-TERM-SYLLABUS.PDF",
  },
  {
    name: "Class VII Mid Term Syllabus",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023-24/CLASS-VII-MID-TERM-SYLLABUS.PDF",
  },
  {
    name: "Class VIII Mid Term Syllabus",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023-24/CLASS-VIII-MID-TERM-SYLLABUS.PDF",
  },
  {
    name: "Class IX Mid Term Syllabus",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023-24/CLASS-IX-MID-TERM-SYLLABUS.PDF",
  },
  {
    name: "Class X PT-3 Syllabus",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023-24/CLASS-X-PT-3-SYLLABUS.PDF",
  },
  {
    name: "Class XI Mid Term Syllabus",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023-24/CLASS-XI-MID-TERM-SYLLABUS.PDF",
  },
  {
    name: "Class XII UT-3 Syllabus",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023-24/CLASS-XII-UT-3-SYLLABUS.PDF",
  },

  {
    name: "Class III Periodic Test Syllabus",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023-24/CLASS-III-PERIODIC-I-SYLLABUS-2023-24.pdf",
  },
  {
    name: "Class IV Periodic Test Syllabus",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023-24/CLASS-IV-PERIODIC-I-SYLLABUS-2023-24.pdf",
  },

  {
    name: "Class V Periodic Test Syllabus",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023-24/CLASS-V-PERIODIC-1-SYLLABUS-2023-24.pdf",
  },

  {
    name: "Prep (2023 - 2024)",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023-24/CLASS-III-PERIODIC-I-SYLLABUS-2023-24.pdf",
  },
  {
    name: "Prep (2023 - 2024)",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023-24/CLASS-III-PERIODIC-I-SYLLABUS-2023-24.pdf",
  },
  {
    name: "Prep (2023 - 2024)",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023-24/PREP.pdf",
  },
  {
    name: "Nursery (2023 - 2024)",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023-24/NURSERY.pdf",
  },
  {
    name: "Class I (2023 - 2024)",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023-24/Class%20I.pdf",
  },
  {
    name: "Class II (2023 - 2024)",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023-24/Class%20II.pdf",
  },
  {
    name: "Class III (2023 - 2024)",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023-24/Class%20III.pdf",
  },
  {
    name: "Class IV (2023 - 2024)",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023-24/Class%20IV.pdf",
  },
  {
    name: "Class V (2023 - 2024)",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023-24/Class%20V.pdf",
  },
  {
    name: "Class VI (2023 - 2024)",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023-24/Class-VI.pdf",
  },
  {
    name: "Class VII (2023 - 2024)",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023-24/Class-VII.pdf",
  },
  {
    name: "Class VIII (2023 - 2024)",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023-24/Class-VIII.pdf",
  },
  {
    name: "Class IX (2023 - 2024)",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023-24/Class-IX.pdf",
  },
  {
    name: "Class X (2023 - 2024)",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023-24/Class-X.pdf",
  },
  {
    name: "Class XI (2023 - 2024)",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023-24/Class-XI.pdf",
  },
  {
    name: "Class XII (2023 - 2024)",
    subjectPdf:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023-24/Class-XII.pdf",
  }, */
  // {
  //   id: 1,
  //   name: "Class Nursery",
  //   subjectPdf:
  //     "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/YEARLY%20SYLLABUS%20NURSERY.docx",
  // },
  // {
  //   id: 2,
  //   name: "Class Prep",
  //   subjectPdf:
  //     "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/YEARLY%20SYLLABUS%20PREP.docx",
  // },
  // {
  //   id: 1,
  //   name: "Class I Syllabus (Jan - March) 2023",
  //   subjectPdf: "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023/CLASS-I-JAN-FEB-MARCH-SYLLABUS(2022-23).pdf"
  // },
  // {
  //   id: 1,
  //   name: "Class I Syllabus",
  //   subjectPdf: "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/Class-I-syllabus-Oct-Dec-2022-23.pdf"
  // },
  // {
  //   id: 1,
  //   name: "Class I Worksheet & Syllabus - Nov",
  //   subjectPdf: "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/CLASS-I-WORKSHEET-SCHEDULE-AND-SYLLABUS-NOVEMBER.pdf"
  // },
  // {
  //   id: 2,
  //   name: "Class II Syllabus (Jan - March) 2023",
  //   subjectPdf: "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023/CLASS-II-JAN-FEB-MARCH-SYLLABUS(2022-23).pdf"
  // },
  // {
  //   id: 2,
  //   name: "Class II Syllabus",
  //   subjectPdf: "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/Class-II-syllabus-Oct-Dec-2022-23.pdf"
  // },
  // {
  //   id: 1,
  //   name: "Class II Worksheet & Syllabus - Nov",
  //   subjectPdf: "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/CLASS-II-WORKSHEET-SCHEDULE-AND-SYLLABUS-NOVEMBER.pdf"
  // },
  // {
  //   id: 2,
  //   name: "Class III - Annual Exam Syllabus",
  //   subjectPdf: "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023/ANNUAL-EXAM-SYLLABUS-(CLASS-III).pdf"
  // },
  // {
  //   id: 2,
  //   name: "Class III Syllabus (Jan & Feb) 2023",
  //   subjectPdf: "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023/CLASS-III-JAN-FEB-SYLLABUS(2022-23).pdf"
  // },
  // {
  //   id: 3,
  //   name: "Class III Syllabus",
  //   subjectPdf:
  //     "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/Class-III-syllabus-Oct-Dec-2022-23.pdf",
  // },
  // {
  //   id: 1,
  //   name: "Class III Worksheet & Syllabus - Nov",
  //   subjectPdf: "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/CLASS-III-WORKSHEET-SCHEDULE-AND-SYLLABUS-NOVEMBER.pdf"
  // },
  // {
  //   id: 1,
  //   name: "Class III - Periodic I Syllabus",
  //   subjectPdf:
  //     "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/CLASS-III-PERIODIC-I.pdf",
  // },
  // {
  //   id: 1,
  //   name: "Class III - Periodic II Syllabus",
  //   subjectPdf:
  //     "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/PERIODIC-II-SYLLABUS-CLASS-III-(2022-23).pdf",
  // },
  // {
  //   id: 1,
  //   name: "Class III - Half Yearly Examination Syllabus",
  //   subjectPdf:
  //     "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/CLASS-III-HFY-SYLLABUS-(2022-23).pdf",
  // },
  // {
  //   id: 2,
  //   name: "Class IV - Annual Exam Syllabus",
  //   subjectPdf: "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023/ANNUAL-EXAM-SYLLABUS-(CLASS-IV).pdf"
  // },
  // {
  //   id: 2,
  //   name: "Class IV Syllabus (Jan & Feb) 2023",
  //   subjectPdf: "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023/CLASS-IV-JAN-FEB-SYLLABUS(2022-23).pdf"
  // },
  // {
  //   id: 4,
  //   name: "Class IV Syllabus",
  //   subjectPdf:
  //     "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/Class-IV-syllabus-Oct-Dec-2022-23.pdf",
  // },
  // {
  //   id: 1,
  //   name: "Class IV Worksheet & Syllabus - Nov",
  //   subjectPdf: "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/CLASS-IV-WORKSHEET-SCHEDULE-AND-SYLLABUS-NOVEMBER.pdf"
  // },
  // {
  //   id: 1,
  //   name: "Class IV - Periodic I Syllabus",
  //   subjectPdf:
  //     "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/CLASS-IV-PERIODIC-I.pdf",
  // },
  // {
  //   id: 1,
  //   name: "Class IV - Periodic II Syllabus",
  //   subjectPdf:
  //     "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/PERIODIC-II-SYLLABUS-CLASS-IV(2022-23).pdf",
  // },
  // {
  //   id: 1,
  //   name: "Class IV - Half Yearly Examination Syllabus",
  //   subjectPdf:
  //     "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/CLASS-IV-HFY-SYLLABUS-(2022-23).pdf",
  // },
  // {
  //   id: 2,
  //   name: "Class V - Annual Exam Syllabus",
  //   subjectPdf: "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023/ANNUAL-EXAM-SYLLABUS-(CLASS-V).pdf"
  // },
  // {
  //   id: 2,
  //   name: "Class V Syllabus (Jan & Feb) 2023",
  //   subjectPdf: "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2023/CLASS-V-JAN-FEB-SYLLABUS(2022-23).pdf"
  // },
  // {
  //   id: 5,
  //   name: "Class V Syllabus",
  //   subjectPdf:
  //     "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/Class-V-syllabus-Oct-Dec-2022-23.pdf",
  // },
  // {
  //   id: 1,
  //   name: "Class V Worksheet & Syllabus - Nov",
  //   subjectPdf: "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/CLASS-V-WORKSHEET-SCHEDULE-AND-SYLLABUS-NOVEMBER.pdf"
  // },
  // {
  //   id: 1,
  //   name: "Class V - Periodic I Syllabus",
  //   subjectPdf:
  //     "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/CLASS-V-PERIODIC-I.pdf",
  // },
  // {
  //   id: 1,
  //   name: "Class V - Periodic II Syllabus",
  //   subjectPdf:
  //     "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/PERIODIC-II-SYLLABUS-CLASS-V-(2022-23).pdf",
  // },
  // {
  //   id: 1,
  //   name: "Class V - Half Yearly Examination Syllabus",
  //   subjectPdf:
  //     "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/Class-V-HFY-SYLLABUS-(2022-23).pdf",
  // },
  // {
  //   id: 6,
  //   name: "Class VI Syllabus",
  //   subjectPdf:
  //     "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/CLASS-6-SYLLABUS-2022-23.pdf",
  // },
  // {
  //   id: 7,
  //   name: "Class VII Syllabus",
  //   subjectPdf:
  //     "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/CLASS-7-SYLLABUS-2022-23.pdf",
  // },
  // {
  //   id: 8,
  //   name: "Class VIII Syllabus",
  //   subjectPdf:
  //     "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/CLASS-8-SYLLABUS-2022-23.pdf",
  // },
  // {
  //   id: 9,
  //   name: "Class IX Syllabus",
  //   subjectPdf:
  //     "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/CLASS-9-SYLLABUS-2022-23.pdf",
  // },
  // {
  //   id: 10,
  //   name: "Class X Syllabus",
  //   subjectPdf:
  //     "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/CLASS-10-SYLLABUS-2022-23.pdf",
  // },
  // {
  //   id: 11,
  //   name: "Class XI Syllabus",
  //   subjectPdf:
  //     "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2021/CLASSXI.pdf",
  // },
  // {
  //   id: 12,
  //   name: "Class XII Syllabus",
  //   subjectPdf:
  //     "https://chinmayavvdelhi.ac.in/assets/pdfs/syllabus/2022/CLASS-XII.pdf",
  // },

  ,
];

export default syllabus;
